const ClientData = [
    {
        id: 1,
        cover: require("../../Assets/PresClients/1.png")
    },
    {
        id: 2,
        cover: require("../../Assets/PresClients/2.png")
    },
    {
        id: 3,
        cover: require("../../Assets/PresClients/3.png")
    },
    {
        id: 4,
        cover: require("../../Assets/PresClients/4.png")
    },
    {
        id: 5,
        cover: require("../../Assets/PresClients/5.png")
    },
    {
        id: 6,
        cover: require("../../Assets/PresClients/6.png")
    },
    {
        id: 7,
        cover: require("../../Assets/PresClients/7.png")
    },
    {
        id: 8,
        cover: require("../../Assets/PresClients/8.png")
    },
    {
        id: 9,
        cover: require("../../Assets/PresClients/9.png")
    },
    {
        id: 10,
        cover: require("../../Assets/PresClients/10.png")
    },
    {
        id: 11,
        cover: require("../../Assets/PresClients/11.png")
    },
    {
        id: 12,
        cover: require("../../Assets/PresClients/12.png")
    },
    {
        id: 13,
        cover: require("../../Assets/PresClients/13.png")
    },
    {
        id: 14,
        cover: require("../../Assets/PresClients/14.png")
    },
    {
        id: 15,
        cover: require("../../Assets/PresClients/15.png")
    },
    {
        id: 16,
        cover: require("../../Assets/PresClients/16.png")
    },
    {
        id: 17,
        cover: require("../../Assets/PresClients/17.png")
    },
    {
        id: 18,
        cover: require("../../Assets/PresClients/18.png")
    },
    {
        id: 19,
        cover: require("../../Assets/PresClients/19.png")
    },
    {
        id: 20,
        cover: require("../../Assets/PresClients/20.png")
    },
    {
        id: 21,
        cover: require("../../Assets/PresClients/21.png")
    },
    {
        id: 22,
        cover: require("../../Assets/PresClients/22.png")
    },
    {
        id: 23,
        cover: require("../../Assets/PresClients/23.png")
    },
    {
        id: 24,
        cover: require("../../Assets/PresClients/24.png")
    },
    {
        id: 25,
        cover: require("../../Assets/PresClients/25.png")
    },
]

export default ClientData;